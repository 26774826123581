<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="goBackToDocumentPage()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ title }}{{ "Add a document" | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list lines="full">
    <input-item [legend]="'Label' | translate" [labelClass]="'document'">
      <ion-input [(ngModel)]="label" placeholder="-"></ion-input>
    </input-item>

    <input-item [legend]="'Document type' | translate" [labelClass]="'document'">
      <ion-select
        okText="{{ 'OK' | translate }}"
        cancelText="{{ 'Cancel' | translate }}"
        [(ngModel)]="selectedDocumentTypeId"
        (ngModelChange)="somethingChanged()"
        interface="action-sheet"
        #documenttypeselect>
        <ion-select-option *ngFor="let docType of documentTypes" [value]="docType.id">
          {{ docType.label }}
        </ion-select-option>
      </ion-select>
    </input-item>

    <input-item
      *ngIf="!investmentId && !assetId && !initiativeId"
      [legend]="'Perimeter' | translate"
      [labelClass]="'document'">
      <ion-select
        okText="{{ 'OK' | translate }}"
        cancelText="{{ 'Cancel' | translate }}"
        [(ngModel)]="selectedPerimeterId"
        (ngModelChange)="somethingChanged()"
        [disabled]="!!fixedPerimeterId">
        <ion-select-option *ngFor="let perimeter of perimeters" [value]="perimeter.id">
          {{ perimeter.name }}
        </ion-select-option>
      </ion-select>
    </input-item>

    <input-item [legend]="'Link' | translate" [labelClass]="'document'">
      <ion-input
        [(ngModel)]="link"
        #linkInput
        placeholder="-"
        (ionBlur)="validateLink(linkInput.value)"
        (ionFocus)="blur()"
        [class]="!linkIsValid && linkInput.value ? 'invalid' : ''"></ion-input>
    </input-item>
  </ion-list>
</ion-content>

<app-footer-next-button
  nextButton
  (next)="save($event)"
  [label]="'save'"
  [enabled]="isDocumentValid()"
  color="document"></app-footer-next-button>
