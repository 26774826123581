import { Injectable } from "@angular/core";
import * as R from "ramda";
import { combineLatest, from, Observable, Observer, of } from "rxjs";

import { makePerimeter, makePerimeterType, Perimeter, PerimeterType, ReferenceFieldDataType } from "../structs/assets";
import { OfflineService } from "./offline.service";
import { SynchronizationService } from "./synchronization.service";
import { ChangeAction, makeChange } from "@structs/synchronization";
import { getLocalId } from "../structs/utils";
import { ReferenceFieldItem } from "../structs/reference-field";
import { concatMap, map, switchMap, tap } from "rxjs/operators";
import { ScopeService } from "./scope.service";
import { InvestmentsService } from "./investments.service";
import { TranslateService } from "@ngx-translate/core";
import { AlertController } from "@ionic/angular";
import * as moment from "moment";

@Injectable()
export class PerimetersService {
  private deleteInProgress: boolean = false;
  confirmTitle: string;
  confirmDeleteMessage: string;
  cancelButton: string;
  confirmOkButton: string;
  assetOrInvestmentsExistMessage: string;

  constructor(
    private offlineApi: OfflineService,
    private syncApi: SynchronizationService,
    private scope: ScopeService,
    private alertCtrl: AlertController,
    private offline: OfflineService,
    private investmentsService: InvestmentsService,
    private translate: TranslateService
  ) {}

  /**
   * Gets the list of buildings ids. of a perimeter and it's filtered sub-perimeters.
   *
   * @param perimeters The perimeters to retrieve the buildings ids. from.
   */
  public static getBuildingsIds(perimeters: Perimeter[]): number[] {
    if (!perimeters || perimeters.length === 0) {
      return [];
    }

    return R.uniq(
      perimeters.reduce(
        (buildingIds, perimeter) => [
          ...buildingIds,
          ...(perimeter.building_id ? [perimeter.building_id] : []),
          ...PerimetersService.getBuildingsIds(perimeter.sub_perimeters),
        ],
        []
      )
    );
  }

  public getPerimeterTypes(): Observable<PerimeterType[]> {
    return new Observable(observer => {
      this.offlineApi.getConfig("perimeterTypes").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          observer.next(data.map(item => makePerimeterType(item)));
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  private doDeletePerimeter(perimeter: Perimeter): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      let url: string = "/structures/api/perimeters/" + perimeter.id + "/";
      this.syncApi
        .addChange(
          makeChange(ChangeAction.deleteMonoPerimeterAction, url, "delete", {}, null, null, null, perimeter.localId)
        )
        .subscribe(
          () => {
            this.syncApi.signalOfflineChanges().subscribe(
              () => {
                observer.next(true);
                observer.complete();
              },
              () => {
                observer.next(true);
                observer.complete();
              }
            );
          },
          err => {
            observer.error(err);
            observer.next(false);
            observer.complete();
          }
        );
    });
  }

  public deletePerimeter(mainPerimeter: Perimeter, monoPerimeter: Perimeter, childrenPerimeters?: Perimeter[]) {
    this.confirmTitle = this.translate.instant("Deletion");
    this.confirmDeleteMessage = this.translate.instant("Do you want to delete the current perimeter?");
    this.confirmOkButton = this.translate.instant("OK");
    this.cancelButton = this.translate.instant("Cancel");
    this.assetOrInvestmentsExistMessage = this.translate.instant(
      "Assets or investments are attached to this perimeter so it cannot be deleted."
    );

    return new Observable(observer => {
      console.log("deleteInprogress", this.deleteInProgress);
      if (!this.deleteInProgress) {
        let confirm = from(
          this.alertCtrl.create({
            // convert promise to observable migration process
            header: this.confirmTitle,
            message: this.confirmDeleteMessage,
            // enableBackdropDismiss: false,
            buttons: [
              {
                text: this.cancelButton,
                handler: () => {},
              },
              {
                text: this.confirmOkButton,
                handler: () => {
                  this.deleteInProgress = true;
                  combineLatest([
                    this.offline.getAuditSynthesis(mainPerimeter, moment().year()),
                    this.investmentsService.getGlobalInvestments(monoPerimeter),
                  ]).subscribe(
                    ([auditSynthesis, globalInvestments]) => {
                      const buildingId = monoPerimeter.building_id;
                      const assets = auditSynthesis.assets.filter(ass => {
                        let buildingMatch: boolean = ass.building.id === buildingId;
                        if (!buildingMatch && childrenPerimeters) {
                          // We see if the asset belongs to one of the child perimeters
                          buildingMatch = childrenPerimeters
                            .map(childPerimeter => childPerimeter.building_id)
                            .includes(ass.building.id);
                        }
                        return buildingMatch;
                      });
                      const investments = auditSynthesis.investments.filter(inv => inv.buildingId === buildingId);
                      const globals = globalInvestments.filter(inv => inv.buildingId === buildingId);

                      if (assets.length > 0 || investments.length > 0 || globals.length > 0) {
                        const assetsOrInvestmentsExist = from(
                          this.alertCtrl.create({
                            // convert promise to observable migration process
                            header: this.confirmTitle,
                            message: this.assetOrInvestmentsExistMessage,
                            buttons: [
                              {
                                text: this.confirmOkButton,
                                handler: () => {
                                  this.deleteInProgress = false;
                                },
                              },
                            ],
                          })
                        );
                        assetsOrInvestmentsExist.subscribe(c => c.present());
                      } else {
                        let perimetersToDelete = [].concat(monoPerimeter, childrenPerimeters);
                        let perimetersObservable = from(perimetersToDelete); // convert promise to observable migration process
                        perimetersObservable
                          .pipe(
                            concatMap(perimeter => {
                              return this.doDeletePerimeter(perimeter);
                            })
                          )
                          .subscribe(
                            () => {
                              // remove from the list of sub-perimeters
                              perimetersToDelete.map(perimeter => {
                                const index = mainPerimeter.sub_perimeters.indexOf(perimeter);
                                if (index > -1) {
                                  mainPerimeter.sub_perimeters.splice(index, 1);
                                }
                              });
                              this.scope.setCurrentMultiPerimeter(mainPerimeter).subscribe(() => {
                                this.scope.updatePerimeters(mainPerimeter).subscribe(() => {
                                  this.deleteInProgress = false;
                                  observer.next(true);
                                  observer.complete();
                                });
                              });
                            },
                            err => {
                              this.deleteInProgress = false;
                              observer.error(err);
                              observer.complete();
                            }
                          );
                      }
                    },
                    error => {
                      this.deleteInProgress = false;
                    },
                    () => {
                      this.deleteInProgress = false;
                    }
                  );
                },
              },
            ],
          })
        );
        confirm.subscribe(cf => cf.present());
      }
    });
  }

  public addMonoPerimeter(
    mainPerimeter: Perimeter,
    name: string,
    creationYear: number,
    perimeterType: PerimeterType,
    levelParent: number,
    confidence: number,
    parentLocalId: string,
    flooringArea?: number
  ): Observable<Perimeter> {
    return new Observable(observer => {
      const url = "/structures/api/create-sub-perimeter/" + mainPerimeter.id + "/";
      const data = {
        name: name,
        creation_year: creationYear,
        perimeter_type: perimeterType.id,
        level_parent: levelParent,
        local_id: getLocalId(),
        installation_date_confidence: confidence,
        flooring_area: flooringArea || null,
      };
      this.syncApi
        .addChange(makeChange(ChangeAction.addMonoPerimeterAction, url, "post", data, null, null, null, parentLocalId))
        .subscribe(
          () => {
            // Try to synchronize the changes
            const perimeterData = {
              id: -1, // keep a non-zero value for keeping the possibility to add asset to the perimeter
              name: name,
              creation_year: creationYear,
              is_monosite: true,
              level: 0,
              read_only: false,
              sub_perimeters: null,
              building_id: -1,
              cluster: mainPerimeter.cluster,
              perimeter_type: perimeterType.id,
              building: {
                id: -1,
                name: name,
                constructionYear: 0,
              },
              ref_data: null,
              pictures: [],
              level_parent: levelParent,
              level_parent_local: parentLocalId,
              local_id: data.local_id,
              installation_date_confidence: confidence,
              flooring_area: flooringArea,
            };
            const perimeter: Perimeter = makePerimeter(perimeterData);
            this.syncApi.signalOfflineChanges().subscribe(
              () => {
                observer.next(perimeter);
                observer.complete();
              },
              err => {
                observer.error(err);
                observer.complete();
              }
            );
          },
          err => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  public saveMonoPerimeter(
    monoPerimeter: Perimeter,
    name: string,
    creationYear,
    confidence: number,
    flooring_area?
  ): Observable<Perimeter> {
    return new Observable(observer => {
      const url = "/structures/api/update-mono-perimeter/" + monoPerimeter.id + "/";
      const data = {
        name: name,
        creation_year: parseInt(creationYear),
        flooring_area: parseFloat(flooring_area),
        installation_date_confidence: confidence,
      };
      this.syncApi
        .addChange(
          makeChange(ChangeAction.saveMonoPerimeterAction, url, "patch", data, null, "", null, monoPerimeter.localId)
        )
        .subscribe(
          () => {
            // Try to synchronize the changes
            monoPerimeter.name = name;
            monoPerimeter.creationYear = creationYear;
            monoPerimeter.flooring_area = flooring_area;
            monoPerimeter.installationDateConfidence = confidence;
            this.syncApi.signalOfflineChanges().subscribe(
              () => {
                observer.next(monoPerimeter);
                observer.complete();
              },
              err => {
                observer.error(err);
                observer.complete();
              }
            );
          },
          err => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  /**
   * Save the updated perimeter to the localStorage
   * @param perimeter
   * @returns Observable<string>, when storage api set a key,value, it returns the value as string object
   */
  updateOfflinePerimeter(perimeter: Perimeter): Observable<string> {
    /**
     * Filter through Perimeter Repository (all local Perimeters) and update
     * the one that have the same id
     * @param perimetersRepository
     * @param updatedPerimeter
     */
    function updateSinglePerimeter(perimetersRepository: Perimeter[], updatedPerimeter: Perimeter): Perimeter[] {
      return perimetersRepository.map(perimeter =>
        perimeter.id === updatedPerimeter.id ? updatedPerimeter : perimeter
      );
    }

    return this.offlineApi
      .getItem("perimeters")
      .pipe(
        switchMap(perimeters => this.offlineApi.storeItem("perimeters", updateSinglePerimeter(perimeters, perimeter)))
      );
  }

  /**
   * Update cover image from a Perimeter.
   * This will also:
   *  - Update the perimeter from the PerimeterRepository (localstorage with `perimeters` key)
   * @param obj
   */
  savePerimeterCoverImage(obj: {
    perimeter: Perimeter;
    pictureId: number;
    pictureLocalId: string;
  }): Observable<Perimeter> {
    const { perimeter, pictureId, pictureLocalId } = obj;
    const url = `/structures/api/update-perimeter-cover-image/${perimeter.id}/`,
      data = {
        cover_image: pictureId,
        pictureLocalId,
      },
      change = makeChange(
        ChangeAction.updatePerimeterCoverImageAction,
        url,
        "patch",
        data,
        null,
        "",
        null,
        perimeter.localId
      );
    return this.syncApi.addChange(change).pipe(
      switchMap(() => this.syncApi.signalOfflineChanges()),
      switchMap(() =>
        of({
          ...perimeter,
          cover_image: pictureId > 0 ? pictureId : pictureLocalId,
        })
      ),
      tap(multiPerimeter => this.updateOfflinePerimeter(multiPerimeter).subscribe()),
      tap(multiPerimeter => this.scope.setCurrentMultiPerimeter(multiPerimeter).subscribe())
    );
  }

  public saveMultiPerimeter(multiPerimeter: Perimeter, data: Partial<Perimeter>): Observable<Perimeter> {
    return new Observable(observer => {
      const url = "/structures/api/update-multi-perimeter/" + multiPerimeter.id + "/";
      this.syncApi
        .addChange(
          makeChange(ChangeAction.saveMultiPerimeterAction, url, "patch", data, null, "", null, multiPerimeter.localId)
        )
        .subscribe(
          () => {
            // Try to synchronize the changes
            Object.keys(data).map(key => (multiPerimeter[key] = data[key]));
            this.syncApi.signalOfflineChanges().subscribe(
              () => {
                observer.next(multiPerimeter);
                observer.complete();
              },
              err => {
                observer.error(err);
                observer.complete();
              }
            );
          },
          err => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  /**
   * Recursively return the list of ids of the linked perimeters + current perimeter
   * @param perimeter : Perimeter
   * @returns number[] : the list of ids of the linked perimeters + current perimeter
   */
  public static getLinkedPerimetersIds(perimeter: Perimeter): number[] {
    return perimeter.sub_perimeters.reduce(
      (acc, perimeter) => {
        acc.push(perimeter.id, ...this.getLinkedPerimetersIds(perimeter));
        return [...new Set(acc)]; // remove duplicates
      },
      [perimeter.id]
    );
  }

  public getPerimeterById(siteId: number): Observable<{
    multiPerimeter: Perimeter;
    perimeterId: number;
    currentMultiPerimeter: Perimeter;
  }> {
    return combineLatest([this.scope.getPerimeters(), this.scope.getSelectedPerimeter()]).pipe(
      map(([perimeters, currentMultiPerimeter]) => {
        return {
          multiPerimeter: perimeters.find(p => p.id === siteId),
          perimeterId: siteId,
          currentMultiPerimeter: currentMultiPerimeter,
        };
      })
    );
  }

  public getNestedPerimeter(perimeterId: number): Observable<Perimeter> {
    return this.scope.getPerimeters().pipe(
      map(perimeters => {
        // Find the perimeter. If not found, check in the sub-perimeters
        return perimeters.reduce((acc, perimeter) => {
          if (!acc) {
            return this.findPerimeter(perimeter, perimeterId);
          }
          return acc;
        }, null);
      })
    );
  }

  private findPerimeter(perimeter: Perimeter, perimeterId: number): Perimeter {
    if (perimeter.id === perimeterId) {
      return perimeter;
    }
    if (perimeter.sub_perimeters) {
      return perimeter.sub_perimeters.reduce((acc, p) => {
        if (!acc) {
          return this.findPerimeter(p, perimeterId);
        }
        return acc;
      }, null);
    }
    return null;
  }

  public areChildrenPerimetersAllowed(cluster: number, monoPerimeter: Perimeter, site: Perimeter): Observable<boolean> {
    return this.getPerimeterTypes().pipe(
      map(allPerimeterTypes => {
        let perimeterTypes = allPerimeterTypes.filter(elt => {
          return (elt.only_for_clusters.length === 0 || elt.only_for_clusters.indexOf(cluster) >= 0) && elt.level === 2;
        });
        let perimeterType = allPerimeterTypes.find(pType => pType.id === monoPerimeter.perimeterType);
        return (
          perimeterTypes &&
          perimeterTypes.length > 0 &&
          perimeterType &&
          perimeterType.perimeterTypeClass !== 1 && // We don't add a perimeter on global site
          !site.read_only &&
          monoPerimeter.level_parent === null
        );
      })
    );
  }
}
