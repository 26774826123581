import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { BrowserService } from "@services/browser.service";
import { PerimeterPicturesService } from "@services/perimeter-pictures.service";
import { StepsIds } from "@structs";
import { AssetEditService } from "../../../services/asset-edit.service";
import { PicturesService } from "../../../services/pictures.service";
import { Picture } from "../../../structs/base";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "camera",
  templateUrl: "./camera.component.html",
  styleUrls: ["./camera.component.scss"],
})
export class CameraComponent implements OnInit {
  @Input() public pictures: Picture[] = [];
  @Input() public options = null;
  @Input() public isForPerimeter: boolean = false;
  @Output() public changed = new EventEmitter<any>();
  @Output() public addBrowserPictureEvent = new EventEmitter<any>();
  @Output() public addDevicePictureEvent = new EventEmitter<any>();
  @ViewChild("browserFileUpload") browserFileUpload: ElementRef;

  inBrowser: boolean;

  constructor(
    private picturesService: PicturesService,
    public assetEditService: AssetEditService,
    private perimeterPicturesService: PerimeterPicturesService,
    private browserService: BrowserService
  ) {}

  ngOnInit() {
    if (!this.isForPerimeter) {
      this.pictures = this.assetEditService.asset.pictures;
    }

    this.inBrowser = this.browserService.inBrowser();
    if (this.options) {
      if (this.options.fromRoadmap) {
        this.assetEditService.setAsset(this.options.asset, this.options.readOnly || false);
      }
      if (this.options.pictureOnly) {
        this.assetEditService.setAsset(this.options.asset, this.options.readOnly || false);
      }
    }
  }

  ionViewDidEnter() {
    if (!this.inBrowser) {
      this.onCameraClicked();
    }
  }

  onCameraClicked() {
    // On mobile, open the camera
    if (!this.inBrowser) {
      this.picturesService.captureFromCamera().subscribe(path => {
        if (this.options) {
          if (this.options.fromRoadmap) {
            this.assetEditService.addPicture(path);
            this.addDevicePictureEvent.emit(path);
          } else {
            this.assetEditService.addPicture(path);
            const params = this.getNextStep();
            this.changed.emit({
              nextStep: params.nextStep,
              nextLabel: params.nextLabel,
              goNext: false,
              stepValid: true,
            });
          }
        } else if (this.isForPerimeter) {
          const newPicture = this.perimeterPicturesService.createPerimeterPicture(null, path);
          this.pictures.push(newPicture);
          const params = this.getNextStep();
          this.changed.emit({
            pictures: this.pictures,
            nextStep: params.nextStep,
            nextLabel: params.nextLabel,
            goNext: false,
            stepValid: true,
          });
          this.changed.emit(params);
        } else {
          this.assetEditService.addPicture(path);
          const params = this.getNextStep();
          this.changed.emit({
            nextStep: params.nextStep,
            nextLabel: params.nextLabel,
            goNext: true,
            stepValid: true,
          });
        }
      });
      // On desktop, trigger the file upload
    } else {
      this.browserFileUpload.nativeElement.click();
    }
  }

  addBrowserPicture(event) {
    const files: FileList = event.target.files;
    if (files.length > 0) {
      if (this.options) {
        if (this.options.fromRoadmap) {
          for (let i = 0; i < files.length; i++) {
            this.assetEditService.addPicture(null, files.item(i));
          }
          this.addBrowserPictureEvent.emit(files.item(0));
        } else {
          for (let i = 0; i < files.length; i++) {
            this.assetEditService.addPicture(null, files.item(i));
          }
          const params = this.getNextStep();
          this.changed.emit({
            nextStep: params.nextStep,
            nextLabel: params.nextLabel,
            goNext: false,
            stepValid: true,
          });
        }
      } else if (this.isForPerimeter) {
        for (let i = 0; i < files.length; i++) {
          const newPicture = this.perimeterPicturesService.createPerimeterPicture(null, null, files.item(i));
          this.pictures.push(newPicture);
        }
        const params = this.getNextStep();
        this.changed.emit({
          pictures: this.pictures,
          nextStep: params.nextStep,
          nextLabel: params.nextLabel,
          goNext: false,
          stepValid: true,
        });
      } else {
        for (let i = 0; i < files.length; i++) {
          this.assetEditService.addPicture(null, files.item(i));
        }
        const params = this.getNextStep();
        this.changed.emit({
          nextStep: params.nextStep,
          nextLabel: params.nextLabel,
          goNext: false,
          stepValid: true,
        });
      }
    }
  }

  private getNextStep() {
    let nextStep = null;
    let nextLabel: string = "next";
    if (this.isForPerimeter || !this.assetEditService.getPerimeter()) {
      nextStep = StepsIds.PERIMETER;
    } else {
      nextStep = StepsIds.ASSETTYPE;
    }
    return { nextStep, nextLabel };
  }
}
