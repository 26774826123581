<ion-list lines="full">
  <div class="small-space"></div>
  <ng-container *ngFor="let section of questionSections; index as sectionIndex">
    <ion-list lines="full">
      <ion-item-divider
        color="light-gray"
        (click)="visibilityToggle(sectionIndex)"
        [ngClass]="isVisible[sectionIndex] ? 'unfolded-section' : 'folded-section'">
        <ion-label class="ion-text-center" style="width: 90%">
          <span *ngIf="isVisible[sectionIndex]">
            (
            <i class="fal fa-minus"></i>
            )
          </span>
          <span *ngIf="!isVisible[sectionIndex]">
            (
            <i class="fal fa-plus"></i>
            )
          </span>
          {{ section.name }}
          <span [hidden]="!defects[sectionIndex]" class="danger-class">({{ defects[sectionIndex] }})</span>
        </ion-label>
      </ion-item-divider>
      <kpi-notations
        [asset]="asset"
        [questions]="section.expertKpis"
        (changed)="onNotationsChanged($event)"
        [hidden]="!isVisible[sectionIndex]"
        (defectsCount)="updateTotalDefects($any($event), sectionIndex)"></kpi-notations>
    </ion-list>
  </ng-container>
</ion-list>
<div class="bottom-spacer"></div>
