import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "document-summary-item", // eslint-disable-line @angular-eslint/component-selector
  templateUrl: "./document-summary-item.component.html",
  styleUrls: ["./document-summary-item.component.scss"],
})
export class DocumentSummaryItemComponent {
  @Input() public documentCount: number;
  @Input() public documentPercent: number;
  @Input() public legend: string;
  @Input() public syncInProgress: boolean = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public click = new EventEmitter<void>();

  constructor() {}

  public onDocumentClick(event: Event): void {
    event.stopPropagation();
    this.click.emit();
  }
}
