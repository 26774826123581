<ng-container *ngIf="priceSheets$ | async as priceSheets">
  <ion-list lines="full" *ngIf="priceSheets.length > 0">
    <ng-container *ngFor="let priceSheet of priceSheets">
      <ion-item [ngClass]="priceSheet.is_default ? 'if-default' : ''">
        <div class="default-label" *ngIf="priceSheet.is_default">
          {{ "Default price sheet" | translate }}
        </div>
        <!-- Check icons. We can't use a regular ion-checkbox because it messes
        with the click function that we use to open the priceSheet. 
        The ion-checkbox takes any click on the whole item -->
        <i
          class="fa-regular fa-circle-dot"
          *ngIf="priceSheet.id === selectedPriceSheetId && !fromAsset"
          (click)="selectPriceSheet(priceSheet.id)"
          slot="start"></i>
        <i
          class="fa-regular fa-circle"
          *ngIf="priceSheet.id !== selectedPriceSheetId && !fromAsset"
          (click)="selectPriceSheet(priceSheet.id)"
          slot="start"></i>
        <ion-label
          [ngClass]="priceSheet.is_default ? 'label-default' : ''"
          (click)="openPriceSheet(priceSheet, $event)">
          <ion-text class="pricesheet-label style2-font-size" color="pricesheet">
            <span class="text-overflow-ellipsis">{{ priceSheet.label }}</span>
          </ion-text>
          <br />
          <ion-text color="primary" class="style2-font-size">
            {{ priceSheet.unit_price | currency }}
            <span *ngIf="priceSheet.quantity_unit">/ {{ priceSheet.quantity_unit.name }}</span>
            <span [ngClass]="priceSheet.quantity === 1 ? 'quantity-one' : 'quantity-more'">
              {{ "for a quantity of" | translate }} {{ priceSheet.quantity }} {{ priceSheet.quantity_unit?.name }}
            </span>
          </ion-text>
          <ion-text
            color="primary"
            id="quantity"
            *ngIf="showQuantity(priceSheet) && priceSheet.quantity_unit"
            class="style2-font-size">
            ({{ priceSheet.quantity }} {{ priceSheet.quantity_unit.name }})
          </ion-text>
          <br />
          <ion-text color="gray-caption" class="origin">
            {{ "Source" | translate }} : {{ priceSheet.author | username }}
            {{ priceSheet.year }}
          </ion-text>
        </ion-label>
      </ion-item>
    </ng-container>
  </ion-list>

  <div *ngIf="priceSheets.length === 0" class="empty-value">
    <div class="no-results-icon">
      <i class="fa-light fa-file fa-3x"></i>
    </div>
    <div class="no-results-text">
      {{ "No price sheet" | translate }}
    </div>
    <div class="no-results-text" *ngIf="!asset">
      {{ "Please attach the investment to an equipment" | translate }}
    </div>
  </div>
</ng-container>
