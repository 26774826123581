<ion-list *ngIf="mainPerimeter" lines="full">
  <ng-container *ngFor="let perimeterElt of searchedPerimeters">
    <ion-item-sliding
      #item
      *ngIf="
        !perimeterElt.perimeter.level_parent ||
        isCollapsed(perimeterElt.perimeter.level_parent) ||
        childrenPerimetersMode
      ">
      <ion-item color="{{ isSelected(perimeterElt.perimeter) ? 'selected-blue' : null }}">
        <ion-label class="perimeter">
          <div class="chevron-button-container">
            <ion-buttons>
              <ion-button
                class="chevron-button"
                *ngIf="!perimeterElt.perimeter.level_parent && perimeterElt.hasLevelChildren"
                (click)="toggleMultiPerimeterChildren(perimeterElt.perimeter)">
                <i class="fal fa-chevron-right" *ngIf="!isCollapsed(perimeterElt.perimeter.id)"></i>
                <i class="fal fa-chevron-down" *ngIf="isCollapsed(perimeterElt.perimeter.id)"></i>
              </ion-button>
              <ion-button
                *ngIf="
                  perimeterElt.perimeter.level_parent &&
                  !isCollapsed(perimeterElt.perimeter.id) &&
                  !childrenPerimetersMode
                ">
                <i class="fal fa-baby children-icon"></i>
              </ion-button>
            </ion-buttons>
          </div>

          <!-- Thumbnail picture -->
          <div class="picture" (click)="pictureClicked($event, perimeterElt.perimeter)">
            <app-picture
              [picture]="
                perimeterElt.perimeter.pictures.length > 0 ? perimeterElt.perimeter.pictures[0] : pictureEmpty
              "></app-picture>
          </div>

          <div class="info" (click)="selectPerimeter(perimeterElt.perimeter)">
            <div class="text-overflow-ellipsis cropped-label">
              <span class="text-style5">
                {{ perimeterElt.perimeter.name }}
              </span>
            </div>
            <!-- <div class="text-style1 text-overflow-ellipsis">
              {{ perimeterElt.perimeterTypeName }}
            </div> -->
            <div class="assets">
              <span *ngIf="getAssetCount(perimeterElt) > 0" style="text-transform: lowercase">
                {{ getAssetCount(perimeterElt) }} {{ "Linked assets" | translate }}
              </span>
              <span *ngIf="!getAssetCount(perimeterElt)">{{ "No linked equipement" | translate }}</span>
            </div>
            <div class="budget">{{ perimeterElt.budget | currency }} {{ "planed" | translate }}</div>
          </div>
        </ion-label>
      </ion-item>
      <ion-item-options *ngIf="!hideButtons">
        <ion-item-option
          *ngIf="!perimeterElt.perimeter.level_parent"
          (click)="addMonoPerimeter(item, perimeterElt.perimeter)"
          color="mono-perimeter"
          class="add-perimeter">
          <i class="fal fa-plus"></i>
        </ion-item-option>
        <ion-item-option color="primary" (click)="showMonoPerimeter(item, perimeterElt.perimeter)">
          <ion-icon src="assets/img/building-tag.svg"></ion-icon>
        </ion-item-option>
        <ion-item-option (click)="deletePerimeter(item, perimeterElt)" color="delete">
          <i class="fal fa-trash-alt"></i>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ng-container>
</ion-list>
