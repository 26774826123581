<ion-list lines="full">
  <ion-item *ngFor="let tag of tags$ | async">
    <ion-checkbox
      slot="start"
      [checked]="isChecked(tag.id)"
      (ionChange)="checkTag(tag)"
      [disabled]="tag.disabled"
      mode="md"></ion-checkbox>
    <ion-label>{{ tag.name }}</ion-label>
  </ion-item>
</ion-list>
