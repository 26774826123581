import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";

import { User } from "../../../structs/auth";
import { AssetEditService } from "../../../services/asset-edit.service";
import { Asset, StepsIds } from "../../../structs/assets";
import { AuditService } from "../../../services/audit.service";
import { AuditNotation, AuditQuestionSection } from "../../../structs/audit";
import { ErrorsService } from "../../../services/errors.service";
import { AuthService } from "../../../services/auth.service";
import { OfflineService } from "../../../services/offline.service";
import { distinctUntilChanged, filter, switchMap, tap } from "rxjs/operators";
import { Subscription } from "rxjs";
import { Events } from "src/app/services/events.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "asset-expert-notation-segment",
  templateUrl: "./asset-expert-notation.component.html",
  styleUrls: ["./asset-expert-notation.component.scss"],
})
export class AssetExpertNotationComponent implements OnInit, OnDestroy {
  @Input() public hasEnergyTrajectory: boolean = false;

  @Output() public expertModeChanged = new EventEmitter<any>();
  @Output() public notationsChanged = new EventEmitter<{
    notations: AuditNotation;
  }>();
  @Output() public changed = new EventEmitter<any>();

  public readOnly: boolean = false;
  questionSections: AuditQuestionSection[] = [];
  isVisible = [];
  private currentUser: User = null;
  private subscriptions: Subscription[] = [];
  public asset: Asset = null;
  public defects = [];
  public totalDefects = 0;

  constructor(
    private assetEditService: AssetEditService,
    private auditApi: AuditService,
    private authApi: AuthService,
    private errorsApi: ErrorsService,
    private offlineApi: OfflineService,
    public event: Events
  ) {}

  public ngOnInit(): void {
    this.asset = this.assetEditService.asset;
    this.readOnly = this.assetEditService.readOnly;
    this.authApi.getCurrentUser().then(user => {
      this.currentUser = user;
      if (!this.assetEditService.isExpertModeOn(user)) {
        this.expertModeChanged.emit({
          user: this.currentUser,
          isOn: true,
        });
      }
    });

    if (this.assetEditService.addMode) {
      this.subscriptions.push(
        // Adding a new asset
        this.auditApi.getAssetExpertKpiSections(this.asset).subscribe(
          sections => {
            this.questionSections = sections;
            for (let i = 0; i < this.questionSections.length; i++) {
              this.isVisible[i] = false;
              this.defects[i] = 0;
            }
          },
          err => {
            this.errorsApi.signalError(err);
          }
        )
      );
      const params = this.getNextStep();
      this.changed.emit({
        nextStep: params.nextStep,
        goNext: false,
        nextLabel: params.nextLabel,
        stepValid: true,
      });
    } else {
      // Opening an existing asset. We need to update it in realtime if
      // changes are made in the other tabs
      this.subscriptions.push(
        this.offlineApi.assetChangedEvent$
          .pipe(
            filter(assetId => assetId === this.assetEditService.asset.id || this.assetEditService.asset.id === 0),
            switchMap(assetId => this.offlineApi.loadAsset(assetId, this.assetEditService.asset.offlineId)),
            distinctUntilChanged((prev, curr) => prev.notesPictures.size === curr.notesPictures.size),
            switchMap(asset => this.auditApi.getAssetExpertKpiSections(asset))
          )
          .subscribe(
            sections => {
              this.questionSections = sections;
              this.initializeSections();
            },
            err => {
              this.errorsApi.signalError(err);
            }
          ),
        // ...we also need to launch it at least one time even if the asset
        // hasn't changed.
        this.auditApi.getAssetExpertKpiSections(this.asset).subscribe(
          sections => {
            this.questionSections = sections;
            this.initializeSections();
          },
          err => {
            this.errorsApi.signalError(err);
          }
        )
      );
    }

    this.subscriptions.push(
      this.offlineApi.assetChangedEvent$.subscribe(assetId => {
        if (assetId === this.assetEditService.asset.id) {
          this.offlineApi.getAsset(this.asset.id).subscribe(asset => {
            this.asset = asset;
            // TODO refactor : assetEditService is causing some troubles
            this.assetEditService.asset.notesPictures = asset.notesPictures;
          });
        }
      })
    );
  }

  private initializeSections() {
    for (let i = 0; i < this.questionSections.length; i++) {
      this.isVisible[i] = false;
      this.defects[i] = 0;
    }
  }

  public ionViewDidEnter(asset: Asset): void {
    this.readOnly = this.assetEditService.readOnly;
  }

  public onNotationsChanged(event: { notations: AuditNotation }) {
    this.notationsChanged.emit(event);
  }

  visibilityToggle(i: number) {
    this.isVisible[i] = !this.isVisible[i];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public updateTotalDefects(event: Event, sectionIndex: number) {
    this.totalDefects = 0;
    this.defects[sectionIndex] = event;
    this.defects.forEach(sectionDefects => {
      if (sectionDefects !== null) {
        this.totalDefects += sectionDefects;
      }
    });
    this.event.publish("updateExpertTab", this.totalDefects);
  }

  private getNextStep() {
    let nextStep = null;
    let nextLabel: string = "next";
    if (this.assetEditService.hasOtherNotations) {
      nextStep = StepsIds.OTHERNOTATIONS;
    } else if (this.assetEditService.needsParent()) {
      nextStep = StepsIds.PARENT;
    } else {
      nextStep = 0;
      nextLabel = "addAsset";
    }
    return { nextStep, nextLabel };
  }
}
