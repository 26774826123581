import {
  Building,
  makeBuilding,
  Category,
  SubCategory,
  makeCategory,
  makeSubCategory,
  AssetType,
  makeAssetType,
  SuperCategory,
} from "./assets";
import { Picture, ImportableMixin } from "./base";

export const INVESTMENT_PRIORITY_HIGH = 1;
export const INVESTMENT_PRIORITY_MEDIUM = 2;
export const INVESTMENT_PRIORITY_LOW = 3;
export const INVESTMENT_PRIORITY_VERY_HIGH = 4;
export const INVESTMENT_TYPE_NO_ASSET = 0;
export const INVESTMENT_TYPE_ASSET_MANDATORY = 1;
export const INVESTMENT_TYPE_ASSET_OPTIONAL = 2;

export class InvestmentBudgetOrigin {
  constructor(
    public id: number,
    public name: string = "",
    public is_default: boolean = false,
    public ordering: number,
    public price_list: boolean
  ) {}
}

export interface IBudgetScope {
  id: number;
  name: string;
  is_default: boolean;
  order: number;
}

export class InvestmentType {
  constructor(
    public id: number = 0,
    public name: string = "",
    public isDefault: boolean = false,
    public replacement: boolean = false,
    public onlyForAssetTypes: Array<number> = [],
    public renew: boolean = false,
    public maintenance: boolean = false,
    public instructionCurrentAsset: number = INVESTMENT_TYPE_ASSET_OPTIONAL,
    public instructionNextAsset: number = INVESTMENT_TYPE_NO_ASSET
  ) {}
}

export class InvestmentReason {
  constructor(
    public id: number,
    public name: string,
    public isDefault: boolean,
    public onlyForInvestmentTypes: Array<number>,
    public disabled: boolean
  ) {}
}

export interface InvestmentLink {
  id: number;
  name: string;
  ordering: number;
}

export class BillingType {
  constructor(public id: number, public name: string) {}
}

export class InvestmentStatus {
  constructor(
    public color: string = "",
    public columns: string[] = [],
    public id: number = 0,
    public name: string = "",
    public isDefault: boolean = false,
    public isLocked: boolean = false,
    public isValidated: boolean = false,
    public isProposed: boolean = false,
    public isRequest: boolean = false,
    public hypothesis: boolean = false,
    public ordering: number = 0,
    public show_in_charts: boolean = false,
    public related_initiative_can_be_unplanned: boolean = true,
    public isClosed: boolean = false,
    public isEngaged: boolean = false,
    /* if empty, the investment can update all the field */
    public investment_updatable_fields: string[] = []
  ) {}
}

/**
 * Define a transition between two `InvestmentStatus`
 */
export interface InvestmentStatusTransition {
  /** Id. of the departure `InvestmentStatus` */
  fromStatus: number;
  /** Id. of the arrival `InvestmentStatus` */
  toStatus: number;
}

export class InvestmentPriority {
  constructor(
    public id: number,
    public name: string,
    public ordering: number,
    public is_default: boolean,
    public is_flash_investment: boolean
  ) {}
}

export class InvestmentFurtherInformation {
  constructor(public id: number, public name: string) {}
}

export class PostponementReason {
  constructor(public id: number, public name: string, public ordering: number, public is_default: boolean) {}
}

export class Sponsorship {
  constructor(public sponsor: number, public percentage: number) {}
}

export class Invoicing {
  constructor(public recipient: number, public percentage: number) {}
}

export class InvestmentSlice {
  constructor(
    public id: number = 0,
    public year: number = 0,
    public status: InvestmentStatus = new InvestmentStatus(),
    public price: number = 0,
    public additionalPrice: number = 0,
    public addPriceReason: string = "",
    public isExtraWork: boolean = false,
    public comments: string = ""
  ) {}
}

export const getInvestmentSliceData = (slice: InvestmentSlice): any => {
  return {
    id: slice.id,
    year: slice.year,
    price: slice.price,
    additional_price: slice.additionalPrice,
    add_price_reason: slice.addPriceReason,
    status: slice.status.id,
    comments: slice.comments,
    is_extra_work: slice.isExtraWork,
  };
};

export const getInvestmentSliceFullPrice = (slice: InvestmentSlice): number => {
  return slice.price + (slice.additionalPrice || 0);
};

export class InvestmentSpendingSlice {
  constructor(
    public id: number,
    public year: number,
    public atDate: string,
    public spent: number,
    public remaining: number
  ) {}
}

export const getInvestmentSpendingSliceData = (slice: InvestmentSpendingSlice): any => {
  return {
    id: slice.id,
    year: slice.year,
    at_date: slice.atDate.split("T")[0],
    spent: slice.spent,
    remaining: slice.remaining,
  };
};

export class InvestmentCategory {
  constructor(
    public id: number,
    public name: string,
    public code: string,
    /** List of categories id. linked to the investment category */
    public categories: number[],
    /** List of subcategories id. linked to the investment category */
    public subcategories: number[],
    /** List of assettypes id. linked to the investment category */
    public assettypes: number[],
    public children: Array<InvestmentCategory> = [],
    public parentId: number,
    public superCategories: number[],
    public fullName?: string
  ) {}
}

export class InvestmentExecution {
  constructor(public id: number, public name: string) {}
}

export class InvestmentPendingChange {
  constructor(
    public name: string,
    public originalValue: any,
    public sliceBudgetIndex?: number,
    public investmentSliceId?: number,
    public total?: number,
    public originalTotal?: number,
    public backendName?: string
  ) {}
}

export class InvestmentPicture extends Picture {
  constructor(
    public id: number,
    public investment: number,
    public picture: string,
    public thumbnail: string,
    public user: number,
    public datetime: string,
    public localId: string,
    public author: string,
    public localPath?: string,
    public browserFile?: File
  ) {
    super(id, picture, thumbnail, user, datetime, localId, author, localPath, browserFile);
  }
}

export function calculateInvestmentPrice(slices: InvestmentSlice[]): number {
  return slices.reduce((total, slice) => total + getInvestmentSliceFullPrice(slice), 0);
}

export interface TaxonomyCategory {
  id: number;
  name: string;
  activity_number: number;
  objective: number;
}

export interface TaxonomyObjective {
  id: number;
  name: string;
  order: number;
  is_default: boolean;
}

export enum ImportableInvestmentStatus {
  ASSET_LINK = "ASSET_LINK",
  READY = "READY",
  VALIDATED = "VALIDATED",
}

export interface AltInvestment {
  final_schedule_to: number;
  id: number;
  scenario: number;
  status: number;
}

export class Investment implements ImportableMixin<ImportableInvestmentStatus> {
  constructor(
    public id: number = 0,
    public createdOn: Date = new Date(),
    public assetId: number = null,
    public assetOffline: boolean = false,
    public label: string = "",
    public investmentType: InvestmentType = new InvestmentType(),
    public initialSchedule: number = 0,
    public initialScheduleTo: number = 0,
    public finalSchedule: number = 0,
    public finalScheduleTo: number = 0,
    public quantity: number = 0,
    public priority: InvestmentPriority = null,
    public billingType: BillingType = null,
    public comments: string = "",
    public localId: string = "",
    public notes: any = {},
    public sponsorships: Array<Sponsorship> = [],
    public hasInvoicing: boolean = false,
    public invoicing: Array<Invoicing> = [],
    public reasons: Array<InvestmentReason> = [],
    public slices: Array<InvestmentSlice> = [],
    public isCapex: boolean = false,
    public isEstimation: boolean = true,
    public energySaving: number = null,
    public investmentCategory: InvestmentCategory = null,
    public category: Category = null,
    public subCategory: SubCategory = null,
    public assetType: AssetType = null,
    public execution: InvestmentExecution = null,
    public building: Building = null,
    public status: InvestmentStatus = new InvestmentStatus(),
    public spendingSlices: InvestmentSpendingSlice[] = [],
    public durationDeviation: number = 0,
    public lifetimeAfterInvestment: number = 0,
    public pictures: InvestmentPicture[] = [],
    public hasNoImpact: boolean = true,
    public reference: string = null,
    public linked_investment_id: string = "",
    public buildingId: number = 0,
    public monoPerimeterLocalId: string = "",
    public deleting: boolean = false,
    public importedAt?: Date,
    public importSource?: string,
    public importReference?: string,
    public importStatuses?: ImportableInvestmentStatus[],
    public furtherInformation: InvestmentFurtherInformation = null,
    public investmentBudgetOrigin: InvestmentBudgetOrigin = null,
    public taxonomyObjective: TaxonomyObjective = null,
    public taxonomyCategory: TaxonomyCategory = null,
    public priceSheet: number = null,
    public cached_payback: number = null,
    public impactRoadmapIndicators: number[] = [],
    public links: InvestmentLink[] = [],
    public budgetScopes: number[] = [],
    public nextAssetId: number | null = null,
    public scenario?: number,
    public purchaseRequestsCount: number = 0,
    public superCategory: SuperCategory = null
  ) {}

  public getStatusSlices(status: InvestmentStatus): InvestmentSlice[] {
    return this.slices.filter(slice => slice.status.id === status.id).sort((a, b) => a.year - b.year);
  }

  public getCurrentStatusSlices(): InvestmentSlice[] {
    return this.getStatusSlices(this.status);
  }

  /**
   * return the total price for the current status
   */
  public getPrice(): number {
    return calculateInvestmentPrice(this.getCurrentStatusSlices());
  }

  /**
   * return the date range for the current status
   */
  public getSchedule(): Array<number> | null {
    let from: number = 9999;
    let to: number = 0;

    for (let i = 0, l = this.slices.length; i < l; i++) {
      let slice: InvestmentSlice = this.slices[i];
      from = Math.min(from, slice.year);
      to = Math.max(to, slice.year);
    }
    if (from !== 9999) {
      return [from, to];
    } else {
      return null;
    }
  }

  public setPictures(pictures: InvestmentPicture[]): void {
    this.pictures = pictures;
  }

  /**
   * return a list of the years for which there is a slice for this investment
   */
  public getSliceYears(): Array<number> | null {
    return this.slices.map(s => (s.status.id == this.status.id ? s.year : null)).filter(n => n);
  }

  public isPlanned(): boolean {
    return !this.status.isClosed && !this.status.hypothesis;
  }
}

export class Postponement {
  constructor(
    /** Id. of the associate `Investment` */
    public investment: number,
    public yearFrom: number,
    public yearTo: number,
    public price: number,
    public reason: number
  ) {}
}

export function makeSponsorship(jsonElt: any): Sponsorship {
  return new Sponsorship(jsonElt.sponsor, Math.round(Number.parseFloat(jsonElt.percentage)));
}

export function makeInvestmentBudgetOrigin(jsonElt: any): InvestmentBudgetOrigin {
  return new InvestmentBudgetOrigin(jsonElt.id, jsonElt.name, jsonElt.is_default, jsonElt.ordering, jsonElt.price_list);
}

export function makeInvoicing(jsonElt: any): Invoicing {
  return new Invoicing(jsonElt.recipient, Math.round(Number.parseFloat(jsonElt.percentage)));
}

export function makeInvestmentType(jsonElt: any): InvestmentType {
  let onlyForAssetTypes: Array<number> = [];
  if (jsonElt.only_for_asset_types && jsonElt.only_for_asset_types.length > 0) {
    onlyForAssetTypes = jsonElt.only_for_asset_types;
  }
  return new InvestmentType(
    jsonElt.id,
    jsonElt.name,
    jsonElt.is_default,
    jsonElt.replacement,
    onlyForAssetTypes,
    jsonElt.renew,
    jsonElt.maintenance,
    jsonElt.instruction_current_asset,
    jsonElt.instruction_next_asset
  );
}

export function makeInvestmentReason(jsonElt: any): InvestmentReason {
  let onlyForInvestmentTypes: Array<number> = [];
  if (jsonElt.only_for_investment_types && jsonElt.only_for_investment_types.length > 0) {
    onlyForInvestmentTypes = jsonElt.only_for_investment_types;
  }
  return new InvestmentReason(jsonElt.id, jsonElt.name, jsonElt.is_default, onlyForInvestmentTypes, jsonElt.disabled);
}

export function makeInvestmentCategory(jsonElt: any): InvestmentCategory {
  let children: InvestmentCategory[] = [];
  if (jsonElt.children) {
    children = jsonElt.children.map(elt => makeInvestmentCategory(elt));
  }
  return new InvestmentCategory(
    jsonElt.id,
    jsonElt.name,
    jsonElt.code,
    jsonElt.categories,
    jsonElt.subcategories,
    jsonElt.assettypes,
    children,
    jsonElt.parent_id,
    jsonElt.super_categories,
    jsonElt.full_name
  );
}

export function makeInvestmentExecution(jsonElt: any): InvestmentExecution {
  return new InvestmentExecution(jsonElt.id, jsonElt.name);
}

export function makeInvestmentStatus(jsonStatus: any): InvestmentStatus {
  let status: InvestmentStatus = null;
  if (jsonStatus) {
    status = new InvestmentStatus(
      jsonStatus.color,
      jsonStatus.columns,
      jsonStatus.id,
      jsonStatus.name,
      jsonStatus.is_default,
      jsonStatus.is_locked,
      jsonStatus.is_validated,
      jsonStatus.is_proposed,
      jsonStatus.is_request,
      jsonStatus.hypothesis,
      jsonStatus.ordering,
      jsonStatus.show_in_charts,
      jsonStatus.related_initiative_can_be_unplanned,
      jsonStatus.is_closed,
      jsonStatus.is_engaged,
      jsonStatus.investment_updatable_fields
    );
    return status;
  }
}

export function makeInvestmentSlice(jsonSlice: any): InvestmentSlice {
  let slice: InvestmentSlice = null;
  if (jsonSlice) {
    slice = new InvestmentSlice(
      jsonSlice.id,
      +jsonSlice.year,
      makeInvestmentStatus(jsonSlice.status),
      +jsonSlice.price,
      +jsonSlice.additional_price,
      jsonSlice.add_price_reason,
      jsonSlice.is_extra_work,
      jsonSlice.comments
    );
    return slice;
  }
}

export function makeInvestmentSpendingSlice(jsonSlice: any): InvestmentSpendingSlice {
  if (jsonSlice) {
    return new InvestmentSpendingSlice(
      jsonSlice.id,
      +jsonSlice.year,
      jsonSlice.at_date,
      +jsonSlice.spent,
      +jsonSlice.remaining
    );
  }
  return null;
}

/**
 * Create an investment picture from data received from the backend
 */
export function makeInvestmentPicture(jsonData: any): InvestmentPicture {
  return new InvestmentPicture(
    jsonData.id,
    jsonData.investment,
    jsonData.picture,
    jsonData.thumbnail,
    jsonData.user,
    jsonData.datetime,
    jsonData.local_id,
    jsonData.author
  );
}

export function makeInvestmentFurtherInformation(jsonElt: any): InvestmentFurtherInformation {
  return new InvestmentFurtherInformation(jsonElt.id, jsonElt.name);
}

export function makeInvestment(jsonElt: any): Investment {
  let priority = null;
  if (jsonElt.priority) {
    priority = new InvestmentPriority(
      jsonElt.priority,
      jsonElt.name,
      jsonElt.ordering,
      jsonElt.is_default,
      jsonElt.is_flash_investment
    );
  }
  let furtherInformation = null;
  if (jsonElt.further_information) {
    furtherInformation = makeInvestmentFurtherInformation(jsonElt.further_information);
  }

  let billingType = null;
  if (jsonElt.billing_type) {
    billingType = new BillingType(jsonElt.billing_type.id, jsonElt.billing_type.name);
  }

  let notes: any = {};
  if (jsonElt.notes) {
    notes = jsonElt.notes;
  }

  let investmentSlices: InvestmentSlice[] = [];
  if (jsonElt.slices) {
    for (let i = 0; i < jsonElt.slices.length; i++) {
      investmentSlices.push(makeInvestmentSlice(jsonElt.slices[i]));
    }
  }

  let investmentPictures: InvestmentPicture[] = [];
  if (jsonElt.pictures) {
    for (const picture of jsonElt.pictures) {
      investmentPictures.push(makeInvestmentPicture(picture));
    }
  }

  let sponsorships: Sponsorship[] = [];
  if (jsonElt.sponsorships) {
    sponsorships = jsonElt.sponsorships.map(makeSponsorship);
  }

  let invoicing: Invoicing[] = [];
  if (jsonElt.invoicings) {
    invoicing = jsonElt.invoicings.map(makeInvoicing);
  }

  let investmentReasons: InvestmentReason[] = [];
  if (jsonElt.reasons) {
    for (let i = 0; i < jsonElt.reasons.length; i++) {
      investmentReasons.push(makeInvestmentReason(jsonElt.reasons[i]));
    }
  }

  const spendingSlices = jsonElt.spending_slices ? jsonElt.spending_slices.map(makeInvestmentSpendingSlice) : [];
  const impactRoadmapIndicators = jsonElt.impact_roadmap_indicators;

  return new Investment(
    jsonElt.id,
    new Date(jsonElt.created_on),
    jsonElt.asset || null,
    jsonElt.asset ? (jsonElt.asset || 0) === 0 : false,
    jsonElt.label,
    makeInvestmentType(jsonElt.investment_type),
    jsonElt.initial_schedule,
    jsonElt.initial_schedule_to || jsonElt.initial_schedule,
    jsonElt.final_schedule,
    jsonElt.final_schedule_to || jsonElt.final_schedule,
    jsonElt.quantity,
    priority,
    billingType,
    jsonElt.comments,
    jsonElt.local_id ? jsonElt.local_id : "",
    notes,
    sponsorships,
    !!jsonElt.has_invoicing,
    invoicing,
    investmentReasons,
    investmentSlices,
    !!jsonElt.is_capex,
    !!jsonElt.is_estimation,
    jsonElt.energy_saving || 0,
    jsonElt.investment_category ? makeInvestmentCategory(jsonElt.investment_category) : null,
    jsonElt.category ? makeCategory(jsonElt.category) : null,
    jsonElt.sub_category ? makeSubCategory(jsonElt.sub_category) : null,
    jsonElt.asset_type ? makeAssetType(jsonElt.asset_type) : null,
    jsonElt.execution ? makeInvestmentExecution(jsonElt.execution) : null,
    jsonElt.building ? makeBuilding(jsonElt.building) : null,
    jsonElt.status ? makeInvestmentStatus(jsonElt.status) : null,
    spendingSlices,
    jsonElt.duration_deviation,
    jsonElt.lifetime_after_investment,
    investmentPictures,
    jsonElt.has_no_impact,
    jsonElt.reference,
    jsonElt.linked_investment_id,
    jsonElt.building_id || 0,
    "",
    false,
    jsonElt.imported_at ? new Date(jsonElt.imported_at) : null,
    jsonElt.import_source || null,
    jsonElt.import_reference || null,
    jsonElt.import_statuses || null,
    furtherInformation,
    jsonElt.budget_origin ? makeInvestmentBudgetOrigin(jsonElt.budget_origin) : null,
    jsonElt.taxonomy_objective,
    jsonElt.taxonomy_category,
    jsonElt.price_sheet,
    jsonElt.cached_payback,
    impactRoadmapIndicators,
    jsonElt.links,
    jsonElt.budget_scopes,
    jsonElt.next_asset || null,
    jsonElt.scenario,
    jsonElt.purchase_requests_count || 0,
    jsonElt.super_category
  );
}

export function makePostponement(jsonElt: any): Postponement {
  return new Postponement(
    jsonElt.investment,
    jsonElt.year_from,
    jsonElt.year_to,
    Number(jsonElt.price),
    jsonElt.reason
  );
}

export const ACTION_POSTPONE: number = 1;
export const ACTION_DELETE: number = 2;
export const ACTION_CHANGE_STATUS: number = 3;
export const ACTION_CHANGE_PRIORITY: number = 4;

export class InvestmentAction {
  constructor(public id: number, public name: string) {}
}

export function createInvestmentData(inv: Investment): any {
  return {
    investment_type: inv.investmentType.id,
    status: inv.status.id,
    initial_schedule: inv.initialSchedule,
    quantity: inv.quantity,
    priority: inv.priority ? inv.priority.id : null,
    further_information: inv.furtherInformation ? inv.furtherInformation.id : null,
    billing_type: inv.billingType ? inv.billingType.id : null,
    comments: inv.comments,
    taxonomy_objective: inv.taxonomyObjective ? inv.taxonomyObjective.id : null,
    has_invoicing: inv.hasInvoicing,
    energy_saving: inv.energySaving,
    label: inv.label,
    category: inv.category ? inv.category.id : null,
    sub_category: inv.subCategory ? inv.subCategory.id : null,
    investment_category: inv.investmentCategory ? inv.investmentCategory.id : null,
    impact_roadmap_indicators: inv.impactRoadmapIndicators,
    taxonomy_category: inv.taxonomyCategory?.id || null,
    sponsorships: inv.sponsorships
      ? inv.sponsorships.map(sponsorship => ({
          sponsor: sponsorship.sponsor,
          percentage: sponsorship.percentage,
        }))
      : [],
    invoicings: inv.invoicing
      ? inv.invoicing.map(elt => ({
          recipient: elt.recipient,
          percentage: elt.percentage,
        }))
      : [],
    reasons: inv.reasons ? inv.reasons.map(reason => reason.id) : [],
    slices: inv.slices
      ? inv.slices.map(slice => ({
          status: slice.status ? slice.status.id : 0,
          price: slice.price,
          year: slice.year,
          id: null,
        }))
      : [],
    local_id: inv.localId,
    is_capex: inv.isCapex,
    has_no_impact: inv.hasNoImpact,
    reference: inv.reference,
    is_estimation: inv.isEstimation,
    budget_origin: inv.investmentBudgetOrigin?.id || null,
    price_sheet: inv.priceSheet || null,
    links: inv.links ? inv.links.map(link => link.id) : [],
    pictures: inv.pictures || [],
    budget_scopes: inv.budgetScopes,
    next_asset: inv.nextAssetId,
  };
}

export function createPostponementData(postponement: Postponement): any {
  return {
    investment: postponement.investment,
    year_from: postponement.yearFrom,
    year_to: postponement.yearTo,
    price: postponement.price,
    reason: postponement.reason,
  };
}
