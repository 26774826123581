import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ConfigService } from "@services/config.service";
import { ErrorsService } from "@services/errors.service";
import { InvestmentsService } from "@services/investments.service";
import { Asset, Investment, InvestmentReason, RoadmapIndicator } from "@structs";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Component({
  selector: "app-investment-impact",
  templateUrl: "./investment-impact.component.html",
  styleUrls: ["./investment-impact.component.scss"],
})
export class InvestmentImpactComponent implements OnInit {
  @Input() investment: Investment;
  @Input() addMode: boolean = false;
  @Input() modalMode: boolean = false;
  @Input() asset: Asset = null;
  public impacts$: Observable<RoadmapIndicator[]>;
  @Output() public changesHappened = new EventEmitter<{
    changed?: boolean;
    impact?: number[];
  }>();
  private pendingChanges: boolean = false;

  constructor(
    private config: ConfigService,
    private modalCtrl: ModalController,
    private investmentsApi: InvestmentsService,
    private errors: ErrorsService
  ) {}

  ngOnInit() {
    this.impacts$ = this.config.get("roadmapIndicators").pipe(
      map((impacts: RoadmapIndicator[]) => impacts?.sort((a, b) => a.order - b.order) || []),
      tap(impacts => {
        if (this.addMode && (this.investment.investmentType.replacement || this.asset)) {
          // Picking the default impact for replacements or linked investments
          const technicalHealthImpact = impacts.find(impact => impact.technical_state_related);
          if (technicalHealthImpact) {
            this.investment.impactRoadmapIndicators.push(technicalHealthImpact.id);
          }
        }
      })
    );
  }

  public isChecked(roadmapIndicatorId: number): boolean {
    return this.investment.impactRoadmapIndicators.includes(roadmapIndicatorId);
  }

  public checkImpact(roadmapIndicatorId: number) {
    this.pendingChanges = true;
    const index = this.investment.impactRoadmapIndicators.findIndex(indicator => indicator === roadmapIndicatorId);
    if (index === -1) {
      this.investment.impactRoadmapIndicators.push(roadmapIndicatorId);
    } else {
      this.investment.impactRoadmapIndicators.splice(index, 1);
    }
    this.changes();
  }

  public changes() {
    this.pendingChanges = true;
    this.changesHappened.emit({
      impact: this.investment.impactRoadmapIndicators,
    });
  }

  public backButtonClicked() {
    if (this.addMode) {
      this.modalCtrl.dismiss();
    } else {
      const data = {
        impact_roadmap_indicators: this.investment.impactRoadmapIndicators,
      };
      this.investmentsApi.patchInvestment(this.asset, this.investment, data).subscribe(
        // Go back to previous page
        investment => {
          this.modalCtrl.dismiss();
        },
        err => {
          this.errors.signalError(err);
          this.modalCtrl.dismiss();
        }
      );
    }
  }
}
