export class User {
  constructor(
    public id: number,
    public get_user_id: number,
    public email: string,
    public apiKey: string,
    public uuid: string,
    public firstName: string,
    public lastName: string,
    public can_access_administration: boolean,
    public can_access_decision: boolean,
    public can_access_energy: boolean,
    public can_access_import: boolean,
    public can_access_insight: boolean,
    public can_access_management: boolean,
    public can_access_opex: boolean,
    public can_access_reconciliation: boolean,
    public can_access_report: boolean,
    public can_access_sites: boolean,
    public can_access_workflow: boolean,
    public can_lead_workflow: boolean,
    public perimeter_permissions: any[]
  ) {}

  public getFullName(): string {
    return `${this.firstName} ${this.lastName}`.trim();
  }

  public getDisplayName(): string {
    const fullName = this.getFullName();
    return !!fullName ? fullName : this.email;
  }
}
