<ion-item-sliding #item *ngIf="investment && !investment?.deleting">
  <ion-item (click)="onItemClick($event)">
    <ion-label class="investment">
      <div class="investment-links" *ngIf="investment.links?.length">
        <ion-badge *ngFor="let link of investment.links">{{ link.name }}</ion-badge>
      </div>

      <!--The following line is commented out, but should be removed cleanly-->
      <div *ngIf="false" class="picture">
        <app-picture [picture]="picture" (error)="reloadPicture()"></app-picture>
      </div>

      <div class="info">
        <!--show the item label & the icon with its import status-->
        <div class="title">
          <span class="text-style1 text-overflow-ellipsis">
            {{ investment.label || investment.investmentType.name }}
          </span>
          <import-status-badge [investment]="investment"></import-status-badge>
        </div>

        <!--show the budget & the current investment status-->
        <investment-item-budget
          class="info-size"
          [investment]="investment"
          [darkerBudget]="true"></investment-item-budget>

        <!--show the name of the asset it is attached to-->
        <div class="linked-asset-info info-size">
          <ion-row *ngIf="showAttachedAsset === true && asset" class="is-linked ion-justify-content-between">
            <ion-col size="11" class="text-overflow-ellipsis ion-no-padding">
              {{ "Attached to" | translate }} : {{ asset.label }}
            </ion-col>
            <ion-col size="1" class="ion-no-padding">
              <button class="paperclip" (click)="$event.preventDefault(); $event.stopPropagation(); showAsset()">
                <i class="fal fa-link"></i>
              </button>
            </ion-col>
          </ion-row>

          <!--inform the user that the investment is not attached to an asset-->
          <div *ngIf="!asset" class="not-linked">
            <span>{{ "Attached to no equipment" | translate }}</span>
          </div>
        </div>
        <div *ngIf="initiativeInfo" class="linked-initiative-info info-size">
          {{ initiativeInfo }}
        </div>
        <div *ngIf="investImpact" class="info-size impact">
          {{ investImpact }}
        </div>
      </div>
    </ion-label>
  </ion-item>
  <ion-item-options side="end" class="slide-buttons" *ngIf="!forInitiative">
    <ion-item-option
      *ngIf="asset && !investment.investmentType?.replacement"
      color="asset"
      (click)="detachInvestment(item)">
      <i class="fal fa-lg fa-unlink"></i>
    </ion-item-option>
    <ion-item-option color="primary" (click)="editMail(item)" *ngIf="isWorkflowEnabled$ | async">
      <i class="fal fa-lg fa-envelope" color="#c00000" *ngIf="!sendingMailLoading"></i>
      <i class="fal fa-lg fa-circle-notch fa-spin" color="#c00000" *ngIf="sendingMailLoading"></i>
    </ion-item-option>
    <ion-item-option *ngIf="forInitiative" color="investment" (click)="detachFromInitiative(item)">
      <i class="fal fa-unlink"></i>
    </ion-item-option>
    <ion-item-option *ngIf="showDeleteButton" color="delete" (click)="delete(item)">
      <i class="fal fa-lg fa-trash-alt"></i>
    </ion-item-option>
  </ion-item-options>
</ion-item-sliding>

<!-- <investment-add-action-sheet
  #investmentAddActionSheet
></investment-add-action-sheet> -->
